import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import countyData from "../../../Assets/county_data.json"; // Adjust the path based on your project structure

export default function EditTeacherSwapDetails({
  open,
  onClose,
  isAuthenticated,
  currentUser,
  setIsAuthenticated,
}) {
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState(
    currentUser.CountyTo ? currentUser.CountyTo : ""
  );
  const [selectedSubCounty, setSelectedSubCounty] = useState(
    currentUser.SubCountyTo ? currentUser.SubCountyTo : ""
  );
  const [selectedWard, setSelectedWard] = useState(
    currentUser.WardTo ? currentUser.WardTo : ""
  );
  const [selectedSchool, setSelectedSchool] = useState(
    currentUser.SchoolTo ? currentUser.SchoolTo : ""
  );
  const rfSchool = useRef(currentUser.SchoolTo ? currentUser.SchoolTo : "");

  const handleCountyChange = (event) => {
    setSelectedCounty(event.target.value);
    setSelectedSubCounty("");
    setSelectedWard("");
  };

  useEffect(() => {
    if (currentUser) {
      setSelectedCounty(currentUser.CountyTo);
      setSelectedSubCounty(currentUser.SubCountyTo);
      setSelectedWard(currentUser.WardTo);
      setSelectedSchool(currentUser.SchoolTo);
    }
  }, [currentUser]);

  const handleSubCountyChange = (event) => {
    setSelectedSubCounty(event.target.value);
    setSelectedWard("");
  };

  const handleWardChange = (event) => {
    setSelectedWard(event.target.value);
  };

  const editDetails = () => {
    const d = {
      CountyTo: selectedCounty,
      SubCountyTo: selectedSubCounty,
      WardTo: selectedWard,
    };

    if (!d.CountyTo) {
      return setIsError("Please select a county.");
    }
    if (!d.SubCountyTo) {
      return setIsError("Please select a subcounty.");
    }
    if (!d.WardTo) {
      return setIsError("Please select a ward.");
    }

    setIsLoading(true);
    setIsError("");

    fetch(`/api/teachers/profile`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("Update of swap details failed");
      })
      .then((data) => {
        setIsLoading(false);
        setIsError(data.message);
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError("Update failed!");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        My Swap Preferrences
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <FormControl fullWidth margin="normal">
            <InputLabel>Preferred County*</InputLabel>
            <Select
              value={selectedCounty}
              onChange={handleCountyChange}
              label="Preferred County"
            >
              {Object.keys(countyData).map((county) => (
                <MenuItem key={county} value={county}>
                  {county}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" disabled={!selectedCounty}>
            <InputLabel>Preferred SubCounty*</InputLabel>
            <Select
              value={selectedSubCounty}
              onChange={handleSubCountyChange}
              label="Preferred SubCounty"
            >
          
              {selectedCounty &&
                Object.keys(countyData[selectedCounty]).map((subCounty) => (
                  <MenuItem key={subCounty} value={subCounty}>
                    {subCounty}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" disabled={!selectedSubCounty}>
            <InputLabel>Preferred Ward*</InputLabel>
            <Select
              value={selectedWard}
              onChange={handleWardChange}
              label="Preferred Ward"
            >
              {selectedCounty &&
                selectedSubCounty &&
                countyData[selectedCounty][selectedSubCounty].map((ward) => (
                  <MenuItem key={ward} value={ward}>
                    {ward}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {isError && (
            <Alert
              color={isError.includes("success") ? "success" : "error"}
              sx={{ mb: 2 }}
            >
              {isError}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={editDetails}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
