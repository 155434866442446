import { useState } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";
import image from "../Assets/contact.svg";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid2 version 2
import Footer from "../Components/Utils/footer";
import Header from "../Components/Utils/header";
import "../Styles/about.scss";
import { styled } from "@mui/material/styles";

export default function Contact(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhone = (phone) => /^0\d{9}$/.test(phone);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    // Validate all fields
    if (name.trim().length < 2) {
      setError("Name must be at least 2 characters long.");
      setLoading(false);
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }
    if (!validatePhone(phone)) {
      setError("Phone number must be 10 digits and start with 0.");
      setLoading(false);
      return;
    }
    if (subject.trim().length < 3) {
      setError("Subject must be at least 3 characters long.");
      setLoading(false);
      return;
    }
    if (message.trim().length < 10) {
      setError("Message must be at least 10 characters long.");
      setLoading(false);
      return;
    }

    const data = {
      Email: email,
      Name: name,
      Phone: phone,
      Subject: subject,
      Message: message,
    };

    fetch("/api/messages/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("");
      })
      .then((data) => {
        if (data.success) {
          setSuccess(
            "Thank you for contacting TSwapMate. We will respond as soon as possible."
          );
          setTimeout(() => {
            setSuccess("");
          }, 10000);
          setEmail("");
          setName("");
          setPhone("");
          setSubject("");
          setMessage("");
        } else {
          throw new Error();
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError("Message not delivered. Try Again.");
        setTimeout(() => setError(""), 10000);
      });
  };

  return (
    <div className="about">
      <Header />
      <Box sx={{ py: 16, backgroundColor: "#f5f5f5" }}>
        <Container>
          <Grid2 container spacing={4} >
            <Grid2 item xs={12} md={6}>
              <img
                src={image}
                alt=""
                style={{
                  maxHeight: "500px",
                  objectFit: "contain",
                  display: "block",
                  margin: "auto auto auto 0",
                  filter: "drop-shadow(0px 56px 80px rgba(0, 0, 0, 0.4))",
                }}
              />
            </Grid2>
            <Grid2 item xs={12} md={6} className="left">
              <Typography
                sx={{ fontWeight: 900, color: "#252C36" }}
                variant="h2"
                component="h1"
                gutterBottom
              >
                Talk to <span style={{ color: "#FA541C" }}>US</span>
              </Typography>
              <Typography
                sx={{ fontWeight: 400, fontSize: "large", color: "#252C36" }}
                variant="body1"
                paragraph
                gutterBottom
              >
                We respond within 1 to 2 business days on the submitted email.
                However, we will strive to respond as soon as possible.
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
                  <InputLabel>Name</InputLabel>
                  <OutlinedInput
                    label="Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
                  <InputLabel>Email address</InputLabel>
                  <OutlinedInput
                    label="Email address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
                  <InputLabel>Phone</InputLabel>
                  <OutlinedInput
                    label="Phone"
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
                  <InputLabel>Subject</InputLabel>
                  <OutlinedInput
                    label="Subject"
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "1rem" }}>
                  <InputLabel>Message</InputLabel>
                  <OutlinedInput
                    label="Message"
                    type="text"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </FormControl>
                <ColorButton
                  sx={{ my: 1, width: "100%" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Send"}
                </ColorButton>
              </form>

              {error && (
                <Alert severity="warning" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  {success}
                </Alert>
              )}
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      <Footer />
    </div>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#525ceb"),
  backgroundColor: "#525ceb",
  "&:hover": {
    backgroundColor: "#FA541C",
  },
}));
